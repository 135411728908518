import React from 'react'
import { Svg, SvgProps } from 'widgets'

const ActiveIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none" {...props}>
      <g opacity="0.16" filter="url(#filter0_f_22171_12961)">
        <circle cx="0.0625" cy="36" r="20" fill="#03F1E3" />
      </g>
      <defs>
        <filter
          id="filter0_f_22171_12961"
          x="-35.9375"
          y="0"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_22171_12961" />
        </filter>
      </defs>
    </Svg>
  )
}

export default ActiveIcon
