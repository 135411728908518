import { useWeb3React } from '@web3-react/core'
import { TimeDataType } from 'hooks/opvStaking/useGetListPools'
import { useEffect, useMemo, useState } from 'react'
import { useGetWalletData } from 'state/wallet/useGetWalletData'
import styled from 'styled-components'
import HeaderStaking from './HeaderStaking'
import MyStaking from './MyStaking'
import Period from './Period'
import StakingItem from './StakingItem'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-radius: 12px;
  border: 1px solid #ffffff66;
  background: radial-gradient(
    340.54% 169.54% at 119.18% 126.53%,
    rgba(0, 0, 0, 0.44) 0%,
    rgba(6, 6, 6, 0.44) 48.58%,
    rgba(27, 27, 27, 0.44) 100%
  );
`

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  padding: 0 16px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;
    padding: 0 36px;
  }

  ::before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: #363a3d;

    ${({ theme }) => theme.mediaQueries.lg} {
      display: block;
    }
  }
`

type StakingPlanPropType = {
  onApprove: (params: any, onSuccess: any) => Promise<void>
  loading: boolean
  isMyStaking: boolean
  stakingList: any[]
  timeList: any[]
  onMyStaking: () => void
}

const StakingPlan = ({ onApprove, loading, stakingList, timeList, onMyStaking, isMyStaking }: StakingPlanPropType) => {
  const { chainId } = useWeb3React()
  
  // State
  const [currentDuration, setCurrentDuration] = useState<TimeDataType | undefined>(undefined)

  // Data, function by contract
  const walletBalance = useGetWalletData()

  useEffect(() => {
    if (timeList) {
      setCurrentDuration(timeList[0]?.time)
    }
  }, [timeList])

  const planSelected = useMemo(
    () => stakingList?.find((item) => item?.poolId === currentDuration?.plan),
    [currentDuration?.plan, stakingList],
  )

  return (
    <Wrapper>
      <HeaderStaking
        onMyStaking={onMyStaking}
        isMyStaking={isMyStaking}
        totalMyStaking={stakingList?.filter((item) => item?.stake > 0)?.length || 0}
      />

      {!isMyStaking ? (
        <Body>
          <Period
            data={planSelected}
            timeList={timeList}
            currentDuration={currentDuration}
            setCurrentDuration={setCurrentDuration}
          />

          {planSelected && (
            <StakingItem
              data={planSelected}
              walletBalance={chainId === 9000 ? walletBalance?.USDT : walletBalance?.OPX}
              onApprove={onApprove}
              loading={loading}
            />
          )}
        </Body>
      ) : (
        <MyStaking dataList={stakingList?.filter((item) => item?.stake > 0)} />
      )}
    </Wrapper>
  )
}

export default StakingPlan
