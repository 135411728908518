import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  border-bottom: 1px solid #363a3d;

  .btn {
    border: 1px solid #606060;
  }

  .custom-logo {
    padding: 4px;
    border-radius: 50%;
    border: 1px solid #6f6f6f;
    background-color: #000;

    width: 100%;
    max-width: 60px;
    height: auto;
    aspect-ratio: 1/1;

    &:nth-child(2) {
      position: relative;
      z-index: 2;
      left: -6px;
    }
  }
`

type HeaderStakingPropType = {
  onMyStaking: () => void
  isMyStaking: boolean
  totalMyStaking: number
}

const HeaderStaking = ({ onMyStaking, isMyStaking, totalMyStaking }: HeaderStakingPropType) => {
  return (
    <Wrapper className="flex md:flex-row flex-col md:items-center md:justify-between p-4 md:py-8 md:px-[36px] gap-4">
      {isMyStaking ? (
        <p className="md:text-[24px] text-[20px] text-[#F0F6FF]">My Staking ({totalMyStaking})</p>
      ) : (
        <div className="flex items-center gap-3">
          <div className="flex items-center">
            <div className="custom-logo">
              <img src="/images/logo.png" alt="logo" />
            </div>
            <div className="custom-logo">
              <img src="/images/logo.png" alt="logo" />
            </div>
          </div>

          <div className="space-y-1">
            <div className="flex items-center gap-1">
              <p className="text-[#E4E4E7] text-[19px] min-w-[60px]">Stake</p>
              <p className="text-[19px]">OPX</p>
            </div>

            <div className="flex items-center gap-1">
              <p className="text-[#E4E4E7] text-[19px] min-w-[60px]">Earn</p>
              <p className="text-[19px]">OPX</p>
            </div>
          </div>
        </div>
      )}

      <div
        className="btn hover:opacity-80 flex items-center justify-center max-h-[48px] py-3 px-6 cursor-pointer rounded-[100px] text-[#03f1e3] text-base"
        onClick={onMyStaking}
        aria-hidden="true"
      >
        {isMyStaking ? 'Stake' : 'My Staking'}
      </div>
    </Wrapper>
  )
}

export default HeaderStaking
