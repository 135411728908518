import { DEFAULT_DECIMAL } from 'config/constants'
import { TimeDataType } from 'hooks/opvStaking/useGetListPools'
import moment from 'moment'
import { Dispatch, SetStateAction, useMemo } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'widgets'
import ActiveIcon from './ActiveIcon'

const Content = styled.div`
  .period-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 16px 8px;

    border-radius: 12px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    background: #171717;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 24px;
    }

    p {
      letter-spacing: 0.15px;
      color: #9b9c9e;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .active {
    border-top: 2px solid #0bf;
    border-bottom: 2px solid #0bf;
    background: linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0) 100%);

    p {
      background: linear-gradient(180deg, #0bf 0%, #00f0ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  svg {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`

type PeriodTypeProp = {
  timeList: TimeDataType[]
  currentDuration: TimeDataType | undefined
  setCurrentDuration: Dispatch<SetStateAction<TimeDataType | undefined>>
  data: any
}

const Period = ({ timeList, currentDuration, setCurrentDuration, data }: PeriodTypeProp) => {
  const percentAPR = useMemo(() => {
    // APR for farming is not depend to time live of pool.
    if (data) {
      const dailyRewards = (data?.cakePerBlock / +DEFAULT_DECIMAL) * 86400
      const totalAmountOfPool = data?.totalLpSupply / +DEFAULT_DECIMAL
      const rewardPerBlock = dailyRewards * 365
      const percent = (rewardPerBlock / (totalAmountOfPool > 0 ? totalAmountOfPool : 1)) * 100
      return totalAmountOfPool <= 0 ? 0 : percent
    }

    return 0
  }, [data])

  return (
    <div className="flex flex-col gap-6 py-6">
      <p className="text-base">Choose a Period to start staking</p>
      <Content className="grid grid-cols-3 sm:gap-8 gap-3 w-full">
        {timeList ? (
          timeList?.map((time) => (
            <div
              key={time?.plan}
              aria-hidden="true"
              className={currentDuration?.plan === time?.plan ? 'period-item active' : 'period-item'}
              onClick={() => setCurrentDuration(time)}
            >
              <p className="text-center lg:text-[32px] sm:text-[24px] text-base font-bold">{time?.time}</p>
              <p className="text-center md:text-base text-sm font-bold">Days</p>
              {currentDuration?.plan === time?.plan && <ActiveIcon />}
            </div>
          ))
        ) : (
          <>
            <Skeleton width="100%" height={120} />
            <Skeleton width="100%" height={120} />
            <Skeleton width="100%" height={120} />
          </>
        )}
      </Content>

      <div className="flex flex-col gap-4">
        <p className="text-base text-center">Current APY</p>

        <div className="w-full py-4 px-3 rounded-[12px] border-[1px] border-solid border-[#FFFFFF14] text-[#03F1E3] sm:text-[24px] text-[20px] text-center leading-[1.2]">
          APY {percentAPR.toFixed(2)}%
        </div>

        <div className="grid grid-cols-3">
          <div className="flex flex-col items-center gap-1">
            <p className="sm:text-sm text-xs text-center text-[#9B9C9E]">Program duration</p>
            <p className="sm:text-[20px] text-sm text-center text-[#F0F6FF]">
              {timeList?.find((item) => item?.plan === data?.poolId)
                ? timeList?.find((item) => item?.plan === data?.poolId)?.time
                : '--'}{' '}
              days
            </p>
          </div>
          <div className="flex flex-col items-center gap-1">
            <p className="sm:text-sm text-xs text-center text-[#9B9C9E]">Time end</p>
            <p className="sm:text-[20px] text-sm text-center text-[#F0F6FF]">
              {data?.endBlock ? moment(data?.endBlock * 1000).format('MMM DD, YYYY') : '--'}
            </p>
          </div>
          <div className="flex flex-col items-center gap-1">
            <p className="sm:text-sm text-xs text-center text-[#9B9C9E]">Staking Type</p>
            <p className="sm:text-[20px] text-sm text-center text-[#F0F6FF]">
              {data?.isLocked ? 'Locked' : 'Flexible'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Period
