import { Button, Empty } from 'antd'
import CountDownCustom from 'components/CountDown/CountDownCustom'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { setModalStaking } from 'state/modal/actions'
import styled from 'styled-components'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'

const Wrapper = styled.div`
  padding: 16px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 32px 36px;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const StakingItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 12px 12px 12px 24px;
  border-radius: 10px;
  border: 1px solid #ffffff66;
  min-width: 800px;

  .action-btn {
    padding: 10px;
    border-radius: 12px;

    p {
      font-weight: 400;
      text-align: center;
    }

    :not(:disabled) {
      color: #000;
      border: 1px solid transparent;
    }
  }

  .btn-left {
    :not(:disabled) {
      background: #fff;
    }
  }

  .btn-right {
    :not(:disabled) {
      background: #03f1e3;
    }
  }
`

type MyStakingPropType = {
  dataList: any[]
}

const MyStakingItem = ({ data, onModalConfirm }) => {
  const handleClick = (type: string) => {
    onModalConfirm(type, data)
  }

  return (
    <StakingItem>
      <div className="flex items-center gap-6">
        <p className="text-[#F0F6FF] min-w-[100px]">{formatAmountOrigin(+data?.stake)}</p>
        <div className="flex items-center gap-1">
          <img src="/images/staking/token-icon.png" alt="icon" className="w-6 h-6" />
          <p className="text-[#BDCADB]">OPX</p>
        </div>
      </div>

      <div className="flex items-center gap-6">
        <p className="text-[#F0F6FF] min-w-[100px]">{formatAmountOrigin(+data?.earn)}</p>
        <div className="flex items-center gap-1">
          <img src="/images/staking/token-icon.png" alt="icon" className="w-6 h-6" />
          <p className="text-[#BDCADB]">OPX</p>
        </div>
      </div>

      <div className="grid grid-cols-2 w-full  gap-3">
        <Button
          className="btn-left action-btn"
          aria-hidden="true"
          disabled={+data?.earn === 0}
          onClick={() => {
            handleClick('Harvest')
          }}
        >
          <p>Harvest</p>
        </Button>
        <Button
          className="btn-right action-btn"
          aria-hidden="true"
          disabled={data?.isLocked && Date.now() < (data?.startBlock + data?.minimumLockTime) * 1000}
          onClick={() => {
            handleClick('Withdraw')
          }}
        >
          {new Date().getTime() < +data?.finish * 1000 ? (
            <CountDownCustom remains={+data?.finish * 1000} style={{ padding: '0' }} />
          ) : (
            <p>Withdraw</p>
          )}
        </Button>
      </div>
    </StakingItem>
  )
}

const MyStaking = ({ dataList }: MyStakingPropType) => {
  const dispatch = useDispatch()

  const handleModalConfirm = (type: string, stakingItem: any) => {
    dispatch(
      setModalStaking({
        toggle: true,
        dataModal: {
          stakingItem,
          type,
        },
      }),
    )
  }

  return (
    <Wrapper className="flex flex-col gap-4">
      <div
        className="flex flex-col gap-4 md:max-h-[530px] max-h-[400px] p-3"
        style={{ overflowY: dataList?.length > 1 ? 'scroll' : 'auto' }}
      >
        <div className="header pl-6 min-w-[800px]">
          <p>Staked</p>
          <p>Earned</p>
          <p>Action</p>
        </div>
        {dataList?.length > 0 ? (
          dataList?.map((item) => <MyStakingItem key={item?.plan} data={item} onModalConfirm={handleModalConfirm} />)
        ) : (
          <Empty />
        )}
      </div>
    </Wrapper>
  )
}

export default MyStaking
